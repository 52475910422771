//actions
const LOAD_NOTIFICATIONS_PAGE = 'NOTIFICATIONS/LOAD/PAGE';
const LOADING_TRUE = 'LOADING/TRUE';
const LOADING_FALSE = 'LOADING/FALSE';
const UPDATE_NOTIFICATON = 'UPDATE/NOTIFICATION';
const UPDATE_READ_NOTIFICATON_PAGE = 'UPDATE_READ_NOTIFICATON_PAGE';
const LOAD_NOTIFICATIONS_TOTALCOUNT = 'LOAD_NOTIFICATIONS_TOTALCOUNT';
const UPDATE_PAGE_LIST = 'UPDATE_PAGE_LIST';
const ALL_READ = 'ALL_READ';

//actions-creator
export function pageNotifications(notifications) {
  return { type: LOAD_NOTIFICATIONS_PAGE, payload: notifications };
}

export function totalCountNotification(count) {
  return { type: LOAD_NOTIFICATIONS_TOTALCOUNT, payload: count };
}

export function updateStoreNotificationload(notifications) {
  return { type: UPDATE_NOTIFICATON, payload: notifications };
}

export function readStoreUpdatePage(notifications) {
  return { type: UPDATE_READ_NOTIFICATON_PAGE, payload: notifications };
}
export function loadingTruePageNotification() {
  return { type: LOADING_TRUE, payload: null };
}

export function loadingFalsePageNotification() {
  return { type: LOADING_FALSE, payload: null };
}
export function updatePageNotificaitonList(notification) {
  return { type: UPDATE_PAGE_LIST, payload: notification };
}

export function markAllReadPage() {
  return { type: ALL_READ, payload: null };
}

//reducer
export default function reducer(
  state = {
    notifications: [],
    loading: false,
    totalCount: 0
  },
  action
) {
  switch (action.type) {
    case LOAD_NOTIFICATIONS_PAGE:
      return { ...state, notifications: action.payload };
    case UPDATE_PAGE_LIST:
      return { ...state, notifications: [action.payload, ...state.notifications] };
    case LOAD_NOTIFICATIONS_TOTALCOUNT:
      return { ...state, totalCount: action.payload };
    case LOADING_TRUE:
      return { ...state, loading: true };
    case LOADING_FALSE:
      return { ...state, loading: false };
    case ALL_READ:
      return {
        ...state,
        notifications: state.notifications.map((item) => ({ ...item, read: true }))
      };
    case UPDATE_READ_NOTIFICATON_PAGE:
      let allData = state.notifications.map((item) => {
        if (item.id == action.payload.id) {
          return { ...item, read: true };
        }
        return item;
      });
      return { ...state, notifications: allData };
    default:
      return state;
  }
}
